import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import EClogo from "assets/img/EClogowhite.png";
import logo1 from "assets/img/logo1.png";
import phonee from "assets/img/phonee.png";

export default function Indexnavbar2() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <section>
      <section className="xl:block lg:block md:block sm:hidden hidden bg-newshade4 poppins">
        <div className="container mx-auto xl:px-4 lg:px-4 md:px-8">
          <div className="poppins flex xl:pt-5 lg:pt-4 md:pt-4 justify-between">
            <div className="xl:w-8/12 md:w-6/12">
              <img class="xl:w-4/12 lg:w-8/12 md:w-6/12" src={EClogo} />
            </div>
            {/* <div className="">
              <div className="flex xl:pt-7 justify-center lg:pt-6 md:pt-5">
                <a href="/">
                  <p class="xl:text-base text-white blue-on-hover lg:text-base md:text-base">
                    Services
                  </p>
                </a>
                <a href="/">
                  <p class="xl:text-base text-white blue-on-hover xl:ml-12 lg:text-base lg:ml-16 md:text-base md:ml-12">
                    Projects
                  </p>
                </a>
                <a href="/">
                  <p class="xl:text-base text-white blue-on-hover xl:ml-12 lg:text-base lg:ml-16 md:text-base md:ml-12">
                    Contact
                  </p>
                </a>
              </div>
            </div> */}

            <div className="flex items-center justify-between ">
              <div className="">
                <a href="tel:+919036323422">
                <div style={{border: '1px solid #ccc'}} className="pulse rounded-full p-2">
                <img src={phonee} alt="" className="w-6"/>
                </div>
                </a>
              </div>
              <div  className="pl-4">
                <a href="#form">
                <button  style={{border: '1px solid #ccc'}} className="poppins xl:text-sm text-white rounded xl:w-12/12 xl:py-3 lg:text-base lg:px-8 lg:py-2 md:px-4 md:py-2 md:text-base">
                  Enquiry Us
                </button>
                </a>
              </div>
              <a href="#form" className="pl-2">
                <button className="poppins bg-newshade5 xl:text-sm border-3 text-black font-semibold rounded xl:w-12/12 xl:py-3 xl:px-4 lg:text-base lg:px-8 lg:py-2 md:px-4 md:py-2 md:text-base">
                  Contact Sales
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/*TAB AND MOBILE VIEW*/}
      <div>
        <section className=" container mx-auto xl:hidden lg:hidden md:hidden block sm:block md:px-12 px-4 md:pt-3 md:pb-2 pt-2 pb-2 bg-newshade4">
          <div className="w-full relative flex justify-between items-center">
            <div className={`px-0 ${navbarOpen ? "hidden" : ""}`}>
              <div>
                <a href="/">
                  <img
                    src={EClogo}
                    className="   md:w-40 md:h-45  w-52 h-16  cursor-pointer text-1xl  "
                  />
                </a>
              </div>
            </div>
            <div className="">
                <a href="tel:+919036323422">
                <div style={{border: '1px solid #ccc'}} className="pulse rounded-full p-2">
                <img src={phonee} alt="" className="w-4"/>
                </div>
                </a>
              </div>
            {/* <button
              className="bg-transparent cursor-pointer text-1xl text-white font-bold leading-none border-transparent rounded block  outline-none focus:outline-none "
              type="button  "
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {navbarOpen ? (
                <i className=""></i>
              ) : (
                <i className="fas fa-bars"></i>
              )}
            </button> */}
          </div>
        </section>

        <div className="xl:hidden lg:hidden md:hidden sm:block block container mx-auto md:px-12 px-4 bg-newshade4">
          <div
            className={
              "lg:flex flex-grow items-center  lg:bg-opacity-0 lg:shadow-none py-3 xl:pt-1 xl:py-0" +
              (navbarOpen ? " block bg-newshade4" : " hidden")
            }
            id="example-navbar-warning"
          >
            <div className="px-0">
              <div className="xl:w-full relative flex justify-between lg:w-auto lg:static lg:justify-start">
                <div className="pr-4 ">
                  <div>
                    <a href="/">
                      <img
                        src={EClogo}
                        alt=""
                        className=" md:w-40 md:h-45 md:mt-0 h-8  cursor-pointer pr-0  md:pr-0  mt-2 w-40 h-16"
                      />
                    </a>
                  </div>
                </div>
                <button
                  className="cursor-pointer text-1xl text-white  font-semibold leading-none block  outline-none focus:outline-none "
                  type="button"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  x
                </button>
              </div>

              <div className="ml-6">
                <a href="/homepage1">
                  <p className=" cursor-pointer  md:pt-8 blue-on-hover pt-4  md:text-base font  uppercase  text-white blue-on-hover   poppins font-semibold text-sm ">
                    Services
                  </p>
                </a>
              </div>

              <div className="ml-6 ">
                <a href="/About3">
                  <p className=" cursor-pointer md:text-base font  uppercase text-white blue-on-hover poppins font-semibold text-sm md:pt-4 pt-4 blue-on-hover ">
                    Project
                  </p>
                </a>
              </div>

              <div className="ml-6 ">
                <a href="/Services2">
                  <p className=" cursor-pointer  md:text-base font  uppercase text-white blue-on-hover poppins font-semibold text-sm md:pt-4 pt-4">
                    Contact
                  </p>
                </a>
              </div>

              <div className="xl:hidden lg:hidden md:block block md:mt-20 mt-12 ml-6 pb-52">
                <a href="/contact?">
                  <button className="md:px-4 md:py-1  border-3 text-white rounded-md px-4 py-1  ">
                    Enquiry US
                  </button>
                </a>
              </div>

              {/*} <div className="flex xl:hidden lg:hidden md:block block md:mt-16 pb-56 pl-0 mt-8 ">
              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ">
                  <i className="fab fa-facebook"></i>
                </button>
              </a>

              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-linkedin"></i>
                </button>
              </a>

              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-instagram"></i>
                </button>
              </a>

              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-youtube"></i>
                </button>
              </a>
            </div>

            */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
