import React from 'react';
import close from "assets/img/close.webp"

const Popup = ({ imageUrl, closePopup }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">
        <img src={close} className="w-5 popup-close-icon" onClick={closePopup} />
        <img src={imageUrl} alt="Popup" className="popup-image" />
      </div>
    </div>
  );
};

export default Popup;
