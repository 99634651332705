import React, { useState } from "react";
import AccordionFooter from "./AccordionFooter";
import ecfooter1 from "assets/img/ecfooter1.png";
import ecfooter2 from "assets/img/ecfooter2.svg";
import EClogo from "assets/img/EClogowhite.png";
import axios from "axios";
import toast from "react-hot-toast";
import locationn from "assets/img/locationn.webp";

export default function Footer2() {
  {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      message: '',
      services: ''
    });

    const handleSubmit = (e) => {
      e.preventDefault();
      if (formData.name.trim().length == 0) {
        toast.error("Please enter name");
      }
      else if (formData.email.trim().length == 0) {
        toast.error("Please enter email");
      }
      else if (formData.phone.trim().length == 0) {
        toast.error("Please enter phone");
      }
      else if (formData.services.trim().length == 0) {
        toast.error("Please select services");
      }
      else if (formData.message.trim().length == 0) {
        toast.error("Please enter message");
      }
      else {
        axios.post("https://admin.elegantcarveinfotech.in/api/add-enquiry", formData)
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status) {
                toast.success("Thank you for your enquiry. We will get back to you shortly.")
                setFormData({
                  name: '',
                  email: '',
                  phone: '',
                  message: '',
                  services: ''
                });
              }
              else {
                toast.error(response.data.message);
              }
            }
          })
          .catch((error) => {
            toast.error(error.message())
          });
      }
    }

    const accordionData = [
      {
        title: "Reach Us",
        content: [
          <ul key="list">
            <li className="text-xs font py-2">
              <a href="/">Address:25/B Milford Road, New York</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Mail:info@example.com</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Phone:+2 123 654 7898</a>
            </li>
          </ul>,
        ],
      },

      {
        title: "Quick Links",
        content: [
          <ul key="list">
            <li className="text-xs font py-2">
              <a href="/">About Us</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Update News</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Contact Us</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Testimonials</a>
            </li>

            <li className="text-xs font py-2">
              <a href="/">Terms Of Service</a>
            </li>

            <li className="text-xs font py-2">
              <a href="/">Privacy policy</a>
            </li>
          </ul>,
        ],
      },

      {
        title: "Our Services",
        content: [
          <ul key="list">
            <li className="text-xs font py-2">
              <a href="/">Solo & Team Fishing</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Fishing Tour</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Fishing Competitions</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Fishing Guidence</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Fishing Equipments</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Fishing Boats</a>
            </li>
          </ul>,
        ],
      },
    ];

    return (
      <>


        <section className="sections xl:block lg:block md:block sm:block block bg-newshade4 roboto ">
          <div id="form" className="container mx-auto xl:px-4 md:px-12 xl:pt-8 lg:px-8 lg:pt-4 px-4">
            {/* <div>
              <img class="xl:w-24 animate-trigger lg:w-20" src={ecfooter1} />
            </div> */}
            <div>
              <hr className="border-blue-50"></hr>
            </div>
            <div className="poppins  justify-between items-center xl:mt-24 lg:mt-10 md:mt-12 mt-12">
              <h1 className="xl:text-5xl text-center text-white font-weightt  lg:text-4xl md:text-4xl text-3xl">
                Ready to shape your <br>
                </br><span className="font-semibold">valuable idea?</span>
              </h1>
              <div className=" mx-auto poppins xl:w-6/12 bg-white xl:mt-12 lg:mt-12 mt-12 rounded-t-2xl">
                {/* <p className="poppins xl:text-base font-bold text-white text-left lg:text-base">
                  Get In Touch
                </p>
                <h1 className="poppins xl:text-4xl font-weightt text-white xl:pt-4 text-left lg:text-3xl lg:pt-4">
                  Drop Us a Line Or Give Us a Ring
                </h1> */}

                <form className="justify-center xl:px-12 lg:px-6 md:px-6 px-4 xl:pb-16 xl:pt-6 lg:pt-12 lg:pb-16 md:pt-12 md:pb-12 pt-12 pb-12">
                  <div className="xl:w-12/12 mt-5 lg:w-12/12 w-full">
                    <input
                      class="xl:w-12/12  lg:w-12/12 w-full rounded-md h-12"
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      style={{ border: "1px solid #ccc" }}
                    />
                  </div>
                  <input
                    class="xl:w-12/12 lg:w-12/12 w-full mt-4 lg:mt-4 border-1 xl:mt-4 rounded-md h-12"
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    style={{ border: "1px solid #ccc" }}
                  />
                  <input
                    class="xl:w-12/12 border-1 xl:mt-4 w-full mt-4 lg:w-12/12 lg:mt-4 rounded-md h-12"
                    type="number"
                    placeholder="Phone Number"
                    name="phone"
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    style={{ border: "1px solid #ccc" }}
                  />
                  <select
                    class="xl:w-12/12 border-1 xl:mt-4 lg:w-12/12 w-full mt-4 lg:mt-4 rounded-md h-12"
                    id="services"
                    name="services"
                    value={formData.services}
                    onChange={(e) => setFormData({ ...formData, services: e.target.value })}
                    style={{ border: "1px solid #ccc" }}
                  >
                    <option value="" disabled selected>
                      Which Services you are interestes in?
                    </option>
                    <option value="Web Development">Web Development</option>
                    <option value="App Development">App Development</option>
                    <option value="Website Design">Website Design</option>
                    <option value="Landing Page Design">
                      Landing Page Design
                    </option>
                    <option value="Woocommerce"> Woocommerce</option>
                    <option value="Shopify"> Shopify</option>
                  </select>
                  <textarea
                    class="xl:w-12/12 xl:mt-4 lg:w-12/12 w-full mt-4 lg:mt-4 border-1 rounded-md"
                    id="message"
                    name="message"
                    placeholder="Enter your message here..."
                    rows="4"
                    cols=""
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    style={{ border: "1px solid #ccc" }}
                  ></textarea>
                  <div className=" justify-center xl:mt-4 lg:mt-4">
                    <a>
                      <button className="w-12/12 py-3 bg-newshade5 rounded-md poppins font-medium text-black xl:px-24 xl:py-3 lg:px-8 lg:py-2" onClick={handleSubmit}>
                        Enquiry Us
                      </button>
                    </a>
                  </div>
                </form>

              </div>
            </div>
            <div>

            </div>
          </div>
        </section>
        <div className=" " style={{ borderTop: "1px solid #323232" }}></div>
        <div className="bg-newshade4">
          <div className="container mx-auto">
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 pt-6 ">
              <div>
                <div className="justify-center center">
                  <img class="xl:w-8/12 lg:w-8/12 md:w-8/12 w-8/12" src={EClogo} />
                </div>
              </div>

              <div className="xl:hidden lg:hidden md:hidden block flex mx-auto">
                {/* <div className="flex justify-end">
                  <img class="xl:w-32 animate-updown lg:w-20 md:w-24" src={ecfooter2} />
                </div> */}
                <div className="">
                <a href="tel:+919036323422">
                <h1 className="text-center  xl:pt-2 items-center xl:pl-16 ">
                <i className="fa fa-phone text-white text-lg"></i>
                  <span className="text-base poppins pl-3 text-white">+91 9036323422</span>
                </h1>
                </a>
                <a href="mailto:leads@elegantcarveinfotech.in">
                <h1 className="text-center cursor-pointer pt-2 flex xl:pt-6 pb-4 items-center xl:pl-16">
                <i className=" text-white text-lg font-weightt fas fa-envelope"> 
                </i>
                  <p className="text-base poppins pl-3 text-white">leads@elegantcarveinfotech.in</p>
                </h1>
                </a>
                
                
                {/* <h1 className="flex xl:pt-2 xl:pl-16 pb-6">
                <i className=" text-white text-base font-weightt fas fa-building"> 
                  </i>
                  <p className="text-sm poppins pl-3 text-white">
                  Novel Tech Park, #46/4,

Kudlu Gate, Hosur Rd, HSR Extension,

Bengaluru, Karnataka 560068.
                  </p>
                </h1> */}
                </div>
              </div>

              <div className=" xl:mt-6 lg:mt-5">
                <div className="flex justify-center">
                  <a href="https://www.facebook.com/elegantcarve2022">
                    <button className="h-10 w-10 rounded-full xl:text-lg text-white border-10 red-on-hover1 lg:text-lg">
                      <i className="fab fa-facebook"></i>
                    </button>
                  </a>
                  <a href="https://in.linkedin.com/company/elegantcarve">
                    <button className="h-10 w-10 rounded-full xl:text-lg text-white border-10 red-on-hover1 xl:ml-4 lg:text-lg lg:ml-4 ml-4">
                      <i className="fab fa-linkedin"></i>
                    </button>
                  </a>
                  <a href="https://www.instagram.com/elegantcarveinfotech/">
                    <button className="h-10 w-10 rounded-full xl:text-lg text-white border-10 red-on-hover1 xl:ml-4 lg:text-lg lg:ml-4 ml-4">
                      <i className="fab fa-instagram"></i>
                    </button>
                  </a>
                  <a href="https://in.pinterest.com/elegantcarve/">
                    <button className="h-10 w-10 rounded-full xl:text-lg text-white border-10 red-on-hover1 xl:ml-4 lg:text-lg lg:ml-4 ml-4 ">
                      <i className="fab fa-pinterest"></i>
                    </button>
                  </a>
                </div>
                <p className="xl:text-sm text-white xl:pt-6 text-center poppins xl:pt-4 lg:text-sm  text-sm lg:pt-4 pb-6 pt-3">
                  © 2024 Elegant Carve Infotech . All Rights Reserved
                </p>
              </div>



              <div className="xl:block lg:block md:block hidden">
                {/* <div className="flex justify-end">
                  <img class="xl:w-32 animate-updown lg:w-20 md:w-24" src={ecfooter2} />
                </div> */}
                <a href="mailto:leads@elegantcarveinfotech.in">
                  <h1 className="cursor-pointer flex xl:pt-6 items-center xl:pl-16">
                    <i className=" text-white text-lg font-weightt fas fa-envelope">
                    </i>
                    <p className="text-base poppins pl-3 text-white">leads@elegantcarveinfotech.in</p>
                  </h1>
                </a>
                <a href="tel:+919036323422">
                  <h1 className="flex xl:pt-2 items-center xl:pl-16">
                    <i className="fa fa-phone text-white text-lg"></i>
                    <p className="text-base poppins pl-3 text-white">+91 9036323422</p>
                  </h1>
                </a>
                <h1 className="flex items-center xl:pt-2 xl:pl-16 pb-6">
                  <img src={locationn} className="w-5 h-5 text-white text-xl"></img>

                  <p className="text-base poppins pl-4 text-white">
                    Bangalore
                  </p>
                </h1>
                {/* <h1 className="flex xl:pt-2 xl:pl-16 pb-6">
                <i className=" text-white text-base font-weightt fas fa-building"> 
                  </i>
                  <p className="text-sm poppins pl-3 text-white">
                  Novel Tech Park, #46/4,

Kudlu Gate, Hosur Rd, HSR Extension,

Bengaluru, Karnataka 560068.
                  </p>
                </h1> */}
              </div>
            </div>
          </div>
        </div>


        {/*TAB AND MOBILEVIEW*/}
        <section className="xl:hidden lg:hidden md:hidden sm:hidden hidden bg-newshade4 md:pb-4 pb-8">
          <div className="md:px-8 container mx-auto px-4">
            {/* <div>
              <img class=" animate-trigger w-20" src={ecfooter1} />
            </div> */}
            <div>
              <hr className="border-blue-50"></hr>
            </div>

            <div className="poppins ">

              <h1 className="poppins md:text-4xl text-3xl font-weightt text-white md:pt-12 text-center pt-12 pb-6">
                Ready to shape your<br></br>
                <span className="font-bold">valuable idea?</span>
              </h1>

              <form className="md:px-16 py-6 rounded-t-2xl px-4 bg-white ">
                <div className="md:w-12/12 w-12/12 mt-5 ">
                  <input
                    class="md:w-12/12 w-12/12 border-3 rounded-md"
                    type="text"
                    placeholder="First Name"
                    name="FirstName"
                    style={{ border: "1px solid #ccc" }}
                  />
                </div>
                <input
                  class=" md:w-12/12 w-12/12 md:mt-4 mt-4 border-3  rounded-md"
                  type="text"
                  placeholder="Email"
                  name="email"
                  style={{ border: "1px solid #ccc" }}
                />
                <input
                  class="md:w-12/12 w-12/12 border-3 md:mt-4  mt-4 rounded-md"
                  type="number"
                  placeholder="Phone Number"
                  name="phone"
                  style={{ border: "1px solid #ccc" }}
                />
                <select
                  class="md:w-12/12 w-12/12 border-3  md:mt-4 mt-4 rounded-md"
                  id="country"
                  name="country"
                  style={{ border: "1px solid #ccc" }}
                >
                  <option value="" disabled selected>
                    Which Services you are interestes in?
                  </option>
                  <option value="Web Development">Web Development</option>
                  <option value="App Development">App Development</option>
                  <option value="Website Design">Website Design</option>
                  <option value="Landing Page Design">
                    Landing Page Design
                  </option>
                  <option value="Woocommerce"> Woocommerce</option>
                  <option value="Shopify"> Shopify</option>
                </select>
                <textarea
                  class="w-12/12 md:w-12/12 md:mt-4 mt-4  border-3 rounded-md"
                  id="message"
                  name="message"
                  placeholder="Enter your message here..."
                  rows="4"
                  cols=""
                  style={{ border: "1px solid #ccc" }}
                ></textarea>
                <div className="  md:mt-4 mt-4">
                  <a>
                    <button className="w-12/12 bg-newshade5 rounded-md text-darkblack md:px-8 md:py-3 px-8 py-2">
                      Enquiry Us
                    </button>
                  </a>
                </div>
              </form>
              <div>
                <hr className="border-blue-50 md:mt-4 mt-4"></hr>
              </div>
              <div className=" mt-5">
                <div className="flex justify-center">
                  <a href="https://www.facebook.com/elegantcarve2022">
                    <button className="h-10 w-10 rounded-full  text-white border-10 red-on-hover1 text-lg">
                      <i className="fab fa-facebook"></i>
                    </button>
                  </a>
                  <a href="https://in.linkedin.com/company/elegantcarve">
                    <button className="h-10 w-10 rounded-full text-lg text-white border-10 red-on-hover1  md:ml-4 ml-2">
                      <i className="fab fa-linkedin"></i>
                    </button>
                  </a>
                  <a href="https://www.instagram.com/elegantcarveinfotech/">
                    <button className="h-10 w-10 rounded-full text-lg text-white border-10 red-on-hover1 md:ml-4 ml-2">
                      <i className="fab fa-instagram"></i>
                    </button>
                  </a>
                  <a href="https://in.pinterest.com/elegantcarve/">
                    <button className="h-10 w-10 rounded-full  text-white border-10 red-on-hover1 md:ml-4 text-lg ml-2">
                      <i className="fab fa-pinterest"></i>
                    </button>
                  </a>
                </div>
                <p className="text-sm text-white md:pt-4 text-center poppins pt-4">
                  © 2024 Elegant Carve Infotech . All Rights Reserved
                </p>
              </div>
              {/* 
              <div>
                <div className="flex justify-end">
                  <img
                    class=" animate-updown w-20 md:-mt-8 mt-2"
                    src={ecfooter2}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/*endd*/}
      </>
    );
  }
}
